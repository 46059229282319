//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  data () {
    return {
      jsonData: {},
      visible: false,
      confirmLoading: false,
      cardLoading: false
    }
  },
  methods: {
    /**
     * 初始化方法
     */
    open (record) {
      this.jsonData = JSON.parse(record.flowableFormResource.formJson)
      this.visible = true
    },
    /**
     * 關閉界面，置空表單
     */
    handleCancel () {
      this.jsonData = {}
      this.visible = false
    }
  }
}
